import React, { useEffect, useRef, useState } from 'react'
import styles from './Header.module.scss'
import MobileHeader from "./mobile/MobileHeader";
import DesktopHeader from "./desktop/DesktopHeader";

export default function Header({user, style, isMobile, width}) {

    const [revealHeader, _setRevealHeader] = useState(true);

    const revealHeaderRef = useRef(revealHeader);

    let header = useRef();

    const setRevealHeader = bool => {
        revealHeaderRef.current = bool;
        _setRevealHeader(bool);
    }

    let pos = 0;

    const handleScroll = () => {

        const windowPosition = window.pageYOffset;

        if ((windowPosition - pos > 0) && windowPosition > 60 && !!revealHeaderRef.current) {
            setRevealHeader(false);
        }

        if ((pos - windowPosition > 0) && !revealHeaderRef.current) {
            setRevealHeader(true);
        }

        pos = windowPosition;
    };

    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => {
            // window.removeEventListener('mousedown', handleClickOutsideDetails);
            window.removeEventListener('scroll', handleScroll);
            if (document.body.style.overflow === 'hidden') {
                setTimeout(() => document.body.style.overflow = 'auto', 0);
            }
        }
    }, []);

    return (
        <header
            className={styles.header}
            ref={header}
            style={{
                ...{'--header-top': !!revealHeader ? '0' : isMobile ? '-60px' : '-70px'},
                ...style,
            }}
        >
            {
                isMobile
                    ? <MobileHeader user={user} isMobile={isMobile}/>
                    : <DesktopHeader user={user} isMobile={isMobile} width={width}/>
            }
        </header>
    )
}